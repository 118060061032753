<!--
 * @Description: 采购商详情
 * @Author: zhang zhen
 * @Date: 2023-02-16 13:49:33
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-29 11:43:34
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/supplierInfoForUser.vue
-->
<template>
  <div class="supplierInfo view-content">
    <div class="order-info">
      {{ orderStatus }}
      <!--      <a-steps-->
      <!--        progress-dot-->
      <!--        :current="orderStatus == -1 ? 9 : orderStatus == -2 ? 7 : orderStatus - 1"-->
      <!--        size="small"-->
      <!--        v-if="orderProcess == '1'"-->
      <!--      >-->
      <!--        <a-step :title="i" v-for="i in stepsList" :key="i" />-->
      <!--      </a-steps>-->
      <a-steps
        progress-dot
        size="small"
        :current="orderStatus<3 ?0:orderStatus ==4?1:orderStatus ==6?3:orderStatus==7?4:orderStatus==8?5:(orderStatus==-1 || orderStatus==9) ?7:2"
      >
        <a-step>
          <template slot="title">
            创建时间
          </template>
          <span slot="description" style="display: flex;white-space: nowrap;color: #8C8C8C">{{ orderCreateTime }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            签署协议
          </template>
          <span slot="description"
                style="display: flex;white-space: nowrap;color: #8C8C8C"
                v-if="purchaseSignTime">采购商：{{ purchaseSignTime }}</span>
          <span slot="description"
                style="display: flex;white-space: nowrap;color: #8C8C8C"
                v-if="supplierSignTime">供应商：{{ supplierSignTime }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            支付预付款
          </template>
          <span slot="description" style="display: flex;white-space: nowrap;color: #8C8C8C"
                v-if="advanceTime">{{ advanceTime }}</span>
          <span slot="description" @click="handleLoadTransportInfo(this.orderNo,1)"
                style="display: flex;white-space: nowrap;color: #FF6026;cursor: pointer;justify-content: center"
                v-if="advanceTime">下载单据</span>
        </a-step>
        <a-step title="生产发货" />
        <a-step title="商品签收" />
        <a-step>
          <template slot="title">
            支付尾款
          </template>
          <span slot="description" style="display: flex;white-space: nowrap;color: #8C8C8C"
                v-if="balanceTime">{{ balanceTime }}</span>
          <span slot="description"
                style="display: flex;white-space: nowrap;color: #FF6026;cursor: pointer;justify-content: center"
                v-if="balanceTime">下载单据</span>
        </a-step>
        <a-step>
          <template slot="title">
            已完结
          </template>
          <span slot="description" style="display: flex;white-space: nowrap;color: #8C8C8C"
                v-if="fishTime && orderStatus=='FINISHED'">{{ fishTime }}</span>
          <span v-if="orderStatus=='FINISHED'" @click="handleJumpAppraise" slot="description"
                style="display: flex;white-space: nowrap;color: #FF6026;cursor: pointer;justify-content: center">去评价</span>
        </a-step>
      </a-steps>
<!--      <a-steps-->
<!--        progress-dot-->
<!--        :current="-->
<!--          orderStatus == -1-->
<!--            ? 7-->
<!--            : orderStatus == 9-->
<!--            ? 6-->
<!--            : orderStatus >= 3 && orderStatus != 7-->
<!--            ? orderStatus - 1-->
<!--            : orderStatus-->
<!--        "-->
<!--        size="small"-->
<!--        v-else-->
<!--      >-->
<!--        <a-step :title="i" v-for="(i, index) in stepsBList" :key="i" />-->
<!--      </a-steps>-->
    </div>


    <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
      <h2 class="form-title" style="margin-top: 26px">
        <span class="title">合作企业</span>
      </h2>
      <div class="quote-table">
        <div class="quote-table-header">
          <span class="label">采购商：{{ headerInfo.purName || '-' }}</span>
          <div class="label-area">
            <span class="label">供货商：{{ headerInfo.supName || '-' }}</span>
            <a :href="`/companyInfoPage?id=${headerInfo.businessId}`">查看企业</a>
          </div>
        </div>
        <a-table :columns="columnspur" :pagination="false" :data-source="purchaseorderItems" bordered>
          <template slot="footer" slot-scope="currentPageData">
            <div class="footer-info">
              <span class="title">合计（元）</span>
              <div class="total" style="padding-left: 16px">{{ total || 0.0 }}</div>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <h2 class="form-title" style="margin-top: 26px">
      <span class="title">付款信息</span>
    </h2>
    <div data-v-34c89936="" class="ant-descriptions ant-descriptions-bordered">
      <div class="ant-descriptions-view">
        <table>
          <tbody>
          <!--          1044001  预付全款 1044002 预付定金 1044003 货到付款  1044004 月结   1044005  其他-->
          <tr class="ant-descriptions-row"
              v-if=" payInfo&&payInfo.paymentMethodDetail && payInfo.paymentMethod=='1044002'">
            <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="1">定金支付时间</th>
            <td colspan="1" class="ant-descriptions-item-content" rowspan="1" v-if="payInfo.paymentMethodDetail[0]">
              {{ payInfo.paymentMethodDetail[0]['paymentTypeDesc'] }}
              {{ payInfo.paymentMethodDetail[0]['limitDay'] }}个工作日内
            </td>
            <th class="ant-descriptions-item-label ant-descriptions-item-colon">尾款支付时间</th>
            <td colspan="1" class="ant-descriptions-item-content" v-if="payInfo.paymentMethodDetail[1]">
              {{ payInfo.paymentMethodDetail[1]['paymentTypeDesc'] }}
              {{ payInfo.paymentMethodDetail[1]['limitDay'] }}个工作日内
            </td>
          </tr>
          <tr class="ant-descriptions-row"
              v-if=" payInfo&&payInfo.paymentMethodDetail && payInfo.paymentMethod=='1044003'">
            <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="1">货到付款日期</th>
            <td colspan="1" class="ant-descriptions-item-content" rowspan="1" v-if="payInfo.paymentMethodDetail[0]">
              收货后{{ payInfo.paymentMethodDetail[0]['paymentTypeDesc'] }}
              {{ payInfo.paymentMethodDetail[0]['limitDay'] }}个工作日内
            </td>
          </tr>

          <tr class="ant-descriptions-row"
              v-if=" payInfo&&payInfo.paymentMethodDetail && payInfo.paymentMethod=='1044004'">
            <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="1">月结日期</th>
            <td colspan="1" class="ant-descriptions-item-content" rowspan="1" v-if="payInfo.paymentMethodDetail[0]">
              {{ payInfo.paymentMethodDetail[0]['paymentTypeDesc'] }}
              {{ payInfo.paymentMethodDetail[0]['limitDay'] }}个工作日内
            </td>
          </tr>

          <tr class="ant-descriptions-row"
              v-if=" payInfo&&payInfo.paymentMethodDetail && payInfo.paymentMethod=='1044001'">
            <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="1">预付全款日期</th>
            <td colspan="1" class="ant-descriptions-item-content" rowspan="1" v-if="payInfo.paymentMethodDetail[0]">

              {{ payInfo.paymentMethodDetail[0]['paymentTypeDesc'] }}
              <span v-if="payInfo.paymentMethodDetail[0]['paymentDateType']== 3">
                 {{ payInfo.paymentMethodDetail[0]['paymentDate'] }}前
                  </span>
              <span v-else> {{ payInfo.paymentMethodDetail[0]['limitDay'] }}个工作日内</span>
            </td>
          </tr>

          <tr class="ant-descriptions-row">
            <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="1">合作总额</th>
            <td colspan="1" class="ant-descriptions-item-content" rowspan="1">{{ payInfo.orderAmount || 0 }}元</td>
            <th class="ant-descriptions-item-label ant-descriptions-item-colon">实付金额</th>
            <td colspan="1" class="ant-descriptions-item-content">{{ payInfo.payAmount || 0 }}元</td>
          </tr>
          <tr class="ant-descriptions-row">
            <th class="ant-descriptions-item-label ant-descriptions-item-colon">待付金额</th>
            <td colspan="1" class="ant-descriptions-item-content">{{ payInfo.orderAmount - payInfo.payAmount || 0 }}元
            </td>
            <th class="ant-descriptions-item-label ant-descriptions-item-colon">支付方式</th>
            <td colspan="1" class="ant-descriptions-item-content">{{ payInfo.paymentMethodDictName }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- old-->
    <!--    <h2 class="form-title">-->
    <!--      <span class="title">收货/发货信息</span>-->
    <!--    </h2>-->
    <!--    <template v-if="isDifferent">-->
    <!--      <FileListView ref="FileListViewRef" desc="暂无发货单据文件" />-->
    <!--      <FileListView ref="FileListViewRefNew" desc="暂无收货单据文件" />-->
    <!--    </template>-->
    <!--    <template v-else>-->
    <!--      <a-table-->
    <!--        class="table-box"-->
    <!--        :columns="columns"-->
    <!--        :scroll="{ x: 'max-content' }"-->
    <!--        :pagination="false"-->
    <!--        :data-source="dataSource"-->
    <!--      >-->
    <!--        <template slot="address" slot-scope="text, record, index">-->
    <!--          <a-tooltip>-->
    <!--            <template slot="title">-->
    <!--              {{ text }}-->
    <!--            </template>-->
    <!--            {{ text | ellipsis(12) }}-->
    <!--          </a-tooltip>-->
    <!--        </template>-->
    <!--        <template slot="order" slot-scope="text, record, index">-->
    <!--          <img :preview="index" src="/order_pic.png" alt="" class="order" />-->
    <!--        </template>-->
    <!--        <template slot="action" slot-scope="text, record, index">-->
    <!--          <a-space :size="23">-->
    <!--            <a @click="downloadFile(record)" v-if="record.receiveStatus == 2">-->
    <!--              <span style="cursor: pointer;">下载收货单据</span>-->
    <!--            </a>-->
    <!--            <a @click="handleViewOrderInfo(record)">查看收货单</a>-->
    <!--          </a-space>-->
    <!--        </template>-->
    <!--      </a-table>-->
    <!--    </template>-->

    <!-- <ZPagination style="margin-top: 20px;" :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
      @handlePageChange="handleChangePage" /> -->

    <h2 class="form-title">
      <span class="title">收货与发货</span>
    </h2>
    <a-tabs type="card">
      <a-tab-pane
        key="1"
        :tab=" `发货 ${index + 1}`"
        v-for="(i, index) in purchaseorderItems"
        :key="index"
      >
        <a-table
          :pagination="false" bordered
          :columns="columnsModel"
          :scroll="{ x: 'max-content' }"
          :data-source="dataSourceModel"
        >
          <template slot="title" slot-scope="currentPageData">
            <div>散件SKU信息</div>
          </template>
        </a-table>

        <div data-v-34c89936="" class="ant-descriptions ant-descriptions-bordered">
          <div class="ant-descriptions-view">
            <table>
              <tbody>
              <tr class="ant-descriptions-row">
                <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="1">发货周期</th>
                <td colspan="1" class="ant-descriptions-item-content" rowspan="1">30天发货一次</td>
                <th class="ant-descriptions-item-label ant-descriptions-item-colon">收货联系人及联系方式</th>
                <td colspan="1" class="ant-descriptions-item-content">张先生/18888888888</td>
              </tr>
              <tr class="ant-descriptions-row">
                <th class="ant-descriptions-item-label ant-descriptions-item-colon">收货地址</th>
                <td colspan="1" class="ant-descriptions-item-content">浙江省金华市义乌市湘润路与富港大道交叉口国际精炼仓库7栋3-4单元
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <a-table
          :pagination="false" bordered
          :columns="issuedColumns"
          :scroll="{ x: 'max-content' }"
          :data-source="issuedDataSource"
        >
          <template slot="title" slot-scope="currentPageData">
            <div>发货单信息</div>
          </template>

          <template slot="action" slot-scope="text, record, index">
            <a-space :size="23">
              <!--              @click="downloadFile(record)" v-if="record.receiveStatus == 2"-->
              <a>
                <span style="cursor: pointer;">下载单据</span>
              </a>
            </a-space>
          </template>
        </a-table>

      </a-tab-pane>

    </a-tabs>
    <RequirementInfo
      ref="RequirementInfoRef"
      :needSteps="true"
      :showOrderInfo="false"
      :purchaseId="purchaseId"
      :needLoadNow="true"
      :needMoreInfo="false"
      @updateOrderInfo="handleEmitUpdate"
    />

    <h2 class="form-title">
      <span class="title">评价</span>
    </h2>
    <AppraisalInfo :orderNo="orderNo" />
    <ChangeShipping ref="ChangeShippingRef" @ok="handleLoadInfo" />
    <ShippingInfo ref="ShippingInfoRef" />
<!--    <FileListView ref="FileListViewRef" />-->
  </div>
</template>

<script>
import { getAction, downGetFiles, postQueryAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import transportsForm from './transportsForm.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import payVoForm from './payVoForm.vue'
import uploadFile from '@/components/plugins/uploadFileSmall.vue'
import ChangeShipping from './changeShipping.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ShippingInfo from './shippingInfo.vue'
import FileListView from './fileListViewNew.vue'
import RequirementInfo from '@/components/plugins/requirementOrderInfo.vue'
import dayjs from 'dayjs'
import AppraisalInfo from '@/views/requirement/orderManagement/modules/children/appraisalInfo.vue'
import { getCurBusiness } from '@/util/utils'

function downloadImage(url) {
  let name = url.split('/images/')[1]

  if (url.includes('/file/pdf/')) {
    name = url.split('/file/pdf/')[1]
  }
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch(() => alert('Failed to download image.'))
}

export default {
  name: 'supplierInfo',
  components: {
    AppraisalInfo,
    EmptyArea,
    transportsForm,
    payVoForm,
    uploadFile,
    splitLine,
    ChangeShipping,
    ZPagination,
    ShippingInfo,
    FileListView,
    RequirementInfo
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    needEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      headerInfo: {},
      columnspur: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 140
        }, {
          title: '散件名称',
          dataIndex: 'skuName',
          width: 260
        },
        {
          title: '采购数量',
          dataIndex: 'skuQuantity',
          width: 200
        },
        {
          title: '合作单价（元）',
          dataIndex: 'skuPrice',
          width: 200
        },
        {
          title: '小计（元）',
          dataIndex: 'totalAmount',
          width: 250
        }
      ], columnsModel: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 140
        }, {
          title: '散件SKU名称',
          dataIndex: 'skuName',
          width: 260
        },
        {
          title: '发货总数量',
          dataIndex: 'skuQuantity',
          width: 200
        },
        {
          title: '计划单次发货数量',
          dataIndex: 'oneSkuQuantity',
          width: 200
        },
        {
          title: '采购商已收数量',
          dataIndex: 'receiveQuantity',
          width: 250
        },
        {
          title: '供应商已发数量',
          dataIndex: 'issuedQuantity',
          width: 250
        }, {
          title: '已发待收数量',
          dataIndex: 'noreceiveQuantity',
          width: 250
        }
      ], dataSourceModel: [{
        wrapTypeDictName: '瓦楞纸',
        skuName: '我是散件名称',
        skuQuantity: '80,000盒',
        oneSkuQuantity: '5盒',
        receiveQuantity: '102盒',
        issuedQuantity: '100盒',
        noreceiveQuantity: '2盒'
      }], issuedColumns: [
        {
          title: '发货单编号',
          dataIndex: 'shipmentNo',
          width: 140
        }, {
          title: '物流编号',
          dataIndex: 'shipmentOrderNo',
          width: 260
        },
        {
          title: '包装材料',
          dataIndex: 'warpTypeName',
          width: 200
        },
        {
          title: '散件名称',
          dataIndex: 'skuName',
          width: 200
        },
        {
          title: '计划单次发货数量',
          dataIndex: 'skuPlanQuantity',
          width: 250
        },
        {
          title: '实际发货数量',
          dataIndex: 'shipmentQuantity',
          width: 250
        }, {
          title: '发货时间',
          dataIndex: 'shipmentTime',
          width: 250
        }, {
          title: '实际收货数量',
          dataIndex: 'receiveQuantity',
          width: 250
        }
        , {
          title: '收货时间',
          dataIndex: 'realReceiveDate',
          width: 250
        }, {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ], issuedDataSource: [{
        shipmentNo: '0000001',
        shipmentOrderNo: 'sf12345678',
        warpTypeName: '瓦楞纸',
        skuName: '我是散件名称',
        skuPlanQuantity: '10盒',
        shipmentQuantity: '10盒',
        shipmentTime: '2025-03-02',
        receiveQuantity: '10盒',
        realReceiveDate: '2025-03-09'
      }],
      payInfo: {},
      pageNation: {
        total: 0,
        pageSize: 20,
        pageNum: 1
      },
      orderProcess: '1',
      editTransportFee: false,
      stepsList: ['协议待确定', '待签署', '待预付', '待生产', '生产中', '已发货', '已收货', '已付款', '已完结'],
      stepsBList: ['签合同', '已签署', '生产中', '已发货', '运输中', '已收货', '已收款', '已完结'],
      formItemLayout: {
        labelCol: { span: 11 },
        wrapperCol: { span: 13 }
      },
      loading: false,
      orderProductsVoList: [],
      payVo: {},
      transportsVo: {},
      orderStatus: 0,
      showTransports: false,
      dataSource: [],
      total: 0,
      newColumns: [
        {
          title: '发货单据',
          dataIndex: 'shipmentFile',
          width: 120,
          scopedSlots: { customRender: 'shipmentFile' }
        },
        {
          title: '收货单据',
          dataIndex: 'receiveFile',
          width: 120,
          scopedSlots: { customRender: 'receiveFile' }
        }
      ],
      columns: [
        {
          title: '编号',
          dataIndex: 'rowIndex',
          width: 100,
          customRender: (t, r, i) => `${ i + 1 }`
          // customRender: (t, r, i) => `发货单${i + 1}`,
        },
        {
          title: '收货人',
          dataIndex: 'receiveName',
          width: 120
        },
        {
          title: '收货人电话',
          dataIndex: 'receivePhone',
          width: 130
        },
        {
          title: '收货地址',
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
          width: 200
        },
        {
          title: '预计收货时间',
          dataIndex: 'expectedDeliveryDate',
          width: 120
        },
        {
          title: '所需数量',
          dataIndex: 'skuPlanQuantityTotal',
          scopedSlots: { customRender: 'skuPlanQuantityTotal' },
          width: 120
        }, {
          title: '实际发货数量',
          dataIndex: 'shipmentQuantityTotal',
          scopedSlots: { customRender: 'shipmentQuantityTotal' },
          width: 120
        }, {
          title: '实际收货数量',
          dataIndex: 'receiveQuantityTotal',
          width: 120,
          scopedSlots: { customRender: 'receiveQuantityTotal' }
        },
        // {
        //   title: '发货时间',
        //   dataIndex: 'shipmentTime',
        //   width: 120,
        //   align: 'center',
        //   customRender: (t, r, i) => t || '-',
        // },
        // {
        //   title: '收货时间',
        //   dataIndex: 'realReceiveDate',
        //   width: 120,
        //   align: 'center',
        //   customRender: (t, r, i) => t || '-',
        // },
        // {
        //   title: '发货单据',
        //   dataIndex: 'shipmentFile',
        //   width: 100,
        //   scopedSlots: { customRender: 'shipmentFile' },
        // },
        // {
        //   title: '发货状态',
        //   dataIndex: 'shipmentStatus',
        //   scopedSlots: { customRender: 'shipmentStatus' },
        //   width: 80,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          width: 280,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      isDifferent: false,
      orderCreateTime: null,
      supplierSignTime: null,
      purchaseSignTime: null,
      purchaseorderItems: [],
      isSupplier: false,
      isPurchaser: false,
      fishTime: null,
      advanceTime: null,
      balanceTime: null
    }
  },
  created() {
    this.curBusiness = getCurBusiness()
    console.log('this.curBusiness' + getCurBusiness())
    this.isPurchaser = false
    this.isSupplier = false
    if (this.curBusiness.roleType == '2') {
      this.isPurchaser = true
    } else {
      this.isSupplier = true
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangePage({ currentPage, pageSize }) {
      this.pageNation.pageNum = currentPage
      this.pageNation.pageSize = pageSize
      // this.handleLoadData()
    },
    handleDownloadFile(url) {
      if (!url) return this.$message.warning('暂无单据')
      downloadImage(url)
    },
    handleChangePrice(e, item) {
      if (item.count) {
        item.amount = e * item.count
      }
    },
    handleChangeCount(e, item) {
      if (item.price) {
        item.amount = e * item.price
      }
    },
    handleDeleteItem(index) {
      this.orderProductsVoList.splice(index, 1)
    },
    downloadFile(record) {
      if (!record.receiveFile) return this.$message.warning('暂无文件')
      // receiveFile
      downloadImage(record.receiveFile)
    },
    handleLoadInfo() {
      getAction('/order/queryByOrderNo', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          const {
            paymentMethodDetail,
            paymentMethod,
            paymentMethodDictName,
            status,
            orderAmount,
            payAmount,
            purchaseType,
            orderDesignFile,
            createTime,
            contract,
            orderItems,
            supplierBusinessName,
            purchaseBusinessName,
            supplierBusinessId,
            updateTime,
            orderPayments
          } = data
          this.payInfo = {
            orderAmount,
            payAmount,
            paymentMethodDictName,
            paymentMethodDetail: JSON.parse(paymentMethodDetail) || [],
            paymentMethod
          }
          this.headerInfo = {
            supName: supplierBusinessName,
            purName: purchaseBusinessName,
            businessId: supplierBusinessId

          }
          this.orderStatus = status
          this.total = orderAmount || 0
          this.purchaseorderItems = orderItems || []
          this.orderCreateTime = dayjs(createTime).format('YYYY-MM-HH hh:mm:ss')

          if (contract && contract.purchaseSignTime) {
            this.orderProcess = 2
            this.purchaseSignTime = dayjs(contract.purchaseSignTime).format('YYYY-MM-HH hh:mm:ss')
          }
          if (contract && contract.supplierSignTime) {
            this.supplierSignTime = dayjs(contract.supplierSignTime).format('YYYY-MM-HH hh:mm:ss')
            this.orderProcess = 2
          }
          this.fishTime = dayjs(updateTime).format('YYYY-MM-HH hh:mm:ss')
          //预付款 日期
          if (orderPayments.length > 0) {
            this.advanceTime = dayjs(orderPayments.filter(item => item.paymentType == 1)[0].payTime).format('YYYY-MM-HH hh:mm:ss')
            this.orderProcess = 3
          }
          //尾款日期
          if (orderPayments.length > 0) {
            this.balanceTime = dayjs(orderPayments.filter(item => item.paymentType == 2)[0].payTime).format('YYYY-MM-HH hh:mm:ss')
          }
          this.isDifferent = purchaseType == '1043001'
          if (this.isDifferent) {
            if (orderDesignFile) {
              const { designDeliveryFile, designReceiveFile } = orderDesignFile
              if (designDeliveryFile && designDeliveryFile.length) {
                let fileObject = {},
                  fileList = []
                designDeliveryFile.map((i) => {
                  fileObject[i] = '发货单据'
                  fileList.push(i)
                })
                this.$nextTick((_) => {
                  this.$refs.FileListViewRef.handleGetFileList(fileList, fileObject) // 发货文件
                })
              }
              if (designReceiveFile && designReceiveFile.length) {
                let fileObject = {},
                  fileList = []
                designReceiveFile.map((i) => {
                  fileObject[i] = '收货单据'
                  fileList.push(i)
                })
                this.$nextTick((_) => {
                  this.$refs.FileListViewRefNew.handleGetFileList(fileList, fileObject) // 发货文件
                })
              }
            }
          } else {
            // 非设计
            this.handleLoadShippingData()
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleLoadShippingData() {
      getAction('/order/actualShipment/queryByOrderNo', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.dataSource =
            (data &&
              data.map((i) => ({
                ...i,
                address: `${ i.receiveProvinceName }${ i.receiveCityName || '' }${ i.receiveAreaName || '' }${
                  i.receiveDetailAddress || ''
                }`,
                shipmentTime: (i.shipmentTime && dayjs(new Date(i.shipmentTime)).format('YYYY-MM-DD')) || null,
                realReceiveDate: (i.realReceiveDate && dayjs(new Date(i.realReceiveDate)).format('YYYY-MM-DD')) || null,
                skuPlanQuantityTotal: i.shipmentItems.reduce((accumulator, current) => {
                  return accumulator + (current.skuPlanQuantity || 0)
                }, 0),
                receiveQuantityTotal: i.shipmentItems.reduce((accumulator, current) => {
                  return accumulator + (current.receiveQuantity || 0)
                }, 0),
                shipmentQuantityTotal: i.shipmentItems.reduce((accumulator, current) => {
                  return accumulator + (current.shipmentQuantity || 0)
                }, 0)
              }))) ||
            []
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 运输方式编辑 */
    handleEditTransportInfo() {
      this.$refs.transportsForm.handleEdit({ ...this.transportsVo, objectId: this.orderNo })
    },
    /* 支付信息 */
    handleEditPayVoInfo() {
      this.$refs.payVoForm.handleEdit(this.orderNo)
    },
    /* 修改地址信息 */
    handleChangeOrderAddress(item) {
      this.$refs.ChangeShippingRef.handleEdit(item)
    },
    /* 查看订单信息 */
    handleViewOrderInfo(item) {
      this.$refs.ShippingInfoRef.handleEdit(item)
    }, handleEmitUpdate({ cancelOrder, paymentMethod, afterSaleStatus, payAmount, orderStatus }) {
      this.cancelOrder = cancelOrder
      this.paymentMethod = paymentMethod
      this.afterSaleStatus = afterSaleStatus
      this.payAmount = payAmount
      this.orderStatus = orderStatus
    },
    /* 评价 */
    handleJumpAppraise() {
      this.$router.push(
        this.isPurchaser
          ? `/orderManagement/appraiseForUser?orderNo=${ this.orderNo }`
          : `/orderManagement/appraisePurchaser?orderNo=${ this.orderNo }`
      )
    },handleLoadTransportInfo(orderNo,type) {
      postQueryAction('/order/payment/record', {
        orderNo: this.orderNo || orderNo,
      }).then((res) => {
        const { success, message, data } = res
        if (success && data && data.length) {
          let fileObject = {},
            fileList = [];
          for (const element of data) {
            const { paymentVoucher, paymentType } = element
            console.log(paymentType, paymentType == 1 ? '预付款支付单据' : '尾款支付单据')
            if(type==paymentType){
              fileObject[paymentVoucher] ='预付款支付单据'
            }
            else {
              fileObject[paymentVoucher] ='尾款支付单据'
            }
            fileList.push(paymentVoucher)
          }
          this.$nextTick((_) => {
            this.$refs.FileListViewRef.handleGetFileList(fileList, fileObject)
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.supplierInfo {
  background: #fff;

  // border: 1px solid #e4e4e4;
  &-title {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 15px;
    background: #f3f3f3;
    color: #666;
    font-weight: 500;
  }

  &-orderInfo {
    background: #fff;
    padding: 30px 25px;
    border-bottom: 1px solid #e4e4e4;

    .orderNum {
      font-size: 16px;
      color: #999;
      font-weight: 500;
    }

    &-content {
      margin-top: 15px;
      .flexLayout();
    }

    &-left {
      .flexLayout(@direction: column;);
      border-right: 1px solid #e6e6e6;
      padding: 10px 30px 10px 0;

      .label {
        margin-bottom: 8px;
        color: #e12936;
        font-size: 22px;
        font-weight: 500;
      }

      .ant-tag {
        width: 78px;
        text-align: center;
        margin-right: 0;
      }
    }

    &-right {
      flex: 1 0 0;
      .flexLayout(@justifyContent: flex-start;);
      padding: 0 20px;

      .status-step {
        flex: 1;
        .flexLayout(@direction: column; @alignItem: flex-start);
        overflow: hidden;
        position: relative;
        margin-right: 16px;

        .anticon {
          margin-bottom: 8px;
          font-size: 30px;
          margin-left: 8px;
        }

        .label {
          font-size: 16px;
          color: #999;
          font-weight: 500;
        }

        &::after {
          position: absolute;
          top: 16px;
          left: 52px;
          display: block;
          width: 999px;
          height: 1px;
          border-top: 1px dashed #797979;
          content: '';
        }

        &:last-of-type {
          flex: none;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &-userInfo {
    background: #fff;
    padding: 30px;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);

    .action_btn {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    .leftBox {
      width: 240px;
      border-right: 1px solid #e6e6e6;
      padding-right: 40px;
      padding-bottom: 20px;
    }

    .title {
      font-size: 16px;
      color: #666;
      font-weight: 500;
    }

    .rightBox {
      flex: 1 0 0;
      padding-left: 40px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .orderInfo-table {
    &-title,
    &-row {
      .flexLayout();
      text-align: center;
      padding: 20px 15px;
      color: #666;
      position: relative;

      .buttonArea {
        position: absolute;
        bottom: 20px;
        right: 15px;
      }

      .productInfo {
        width: 26%;
      }

      .price {
        width: 16%;
      }

      .subCount {
        width: 20%;
      }

      .payMoney {
        width: 20%;
      }
    }

    &-title {
      background: #eeeeee;
    }

    &-row {
      color: #333;
      border-bottom: 1px solid #e4e4e4;

      .productInfo {
        .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);
        padding-left: 20px;

        img {
          width: 75px;
          height: 75px;
        }

        .info {
          padding-left: 15px;
          .flexLayout(@direction: column; @alignItem: flex-start);

          .subTitle {
            font-size: 16px;
            margin-bottom: 10px;
          }

          .size {
            color: #999;
          }
        }
      }
    }

    .red {
      color: #e12936;
    }

    &-subNum {
      padding: 20px 60px;
      background: #fff;

      ::v-deep .ant-form-item-control {
        text-align: right;
      }

      .lastItem {
        margin-top: 60px;

        ::v-deep .ant-form-item-label label {
          color: #e12936;
        }

        ::v-deep .ant-form-item-children {
          font-size: 22px;
          color: #e12936;
        }
      }
    }
  }
}

.form-title {
  font-size: 15px;
  color: #605f5f;
  margin-bottom: 15px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6026;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);

    img {
      width: 15px;
      margin-right: 4px;
    }
  }
}

.order-info {
  padding: 24px 0;

  &-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #e4e4e5;
    margin-bottom: 25px;

    .title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
    }

    .tag {
      width: 52px;
      height: 21px;
      display: inline-block;
      line-height: 21px;
      background: rgba(5, 194, 156, 0.1);
      border-radius: 4px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #05c29c;
    }
  }
}

.ant-descriptions {
  margin-top: 25px;
  margin-bottom: 32px;

  ::v-deep .ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.65);
  }

  ::v-deep .ant-descriptions-item-content {
    color: rgba(0, 0, 0, 0.85);
  }
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
}

::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

.info span {
  color: rgba(0, 0, 0, 0.85);
}

.productImg {
  width: 60px;
  height: 45px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 8px;
}

.info-table {
  display: flex;
  align-items: center;

  ::v-deep.ant-input {
    height: 38px;
    margin-left: 8px;
  }
}

.add-btn {
  border-color: #d9d9d9;
  color: #595959;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  margin-bottom: 24px;
}

.sum-info {
  width: 100%;
  height: 183px;
  padding: 24px 32px;
  background: #fff5ee;
  border: 1px solid #ef8150;
  border-radius: 8px;

  &-top {
    border-bottom: 1px solid #e4e4e6;
    margin-bottom: 16px;

    &-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #595959;
      padding-bottom: 17px;

      .price {
        color: #FF6026;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  &-price {
    text-align: right;
    font-weight: 500;
    font-size: 16px;
    color: #131212;

    .price-num {
      color: #FF6026;
      font-size: 18px;
    }
  }
}

::v-deep .table-box {
  border-left: 1px solid #e8e9eb;
  border-right: 1px solid #e8e9eb;
  border-top: 1px solid #e8e9eb;
}

::v-deep .ant-table-thead > tr > th {
  background: #f4f5f7;
  padding: 14px 16px;
  font-size: 14px;
}

::v-deep .ant-table-tbody tr > td {
  padding: 9px 16px;
  font-size: 14px;
}

::v-deep .ant-table-row {
  height: 60px !important;
}

img.to_input {
  width: 52px;
}

img.order {
  width: 60px;
  border-radius: 4px;
}

@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
::v-deep .ant-select-sm .ant-select-selection--single {
  height: 24px !important;
}

::v-deep .ant-select-selection__rendered {
  line-height: 22px !important;
}

::v-deep .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
::v-deep .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #21d95e;
}

::v-deep .ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}

::v-deep .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: #21d95e;
}

::v-deep .quote-table .ant-table-footer .footer-info .total {
  width: 24.2%;
}

::v-deep .ant-table-title {
  background: #F4F5F7;
  height: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
