<!--
 * @Description: 订单 - 概览
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:48:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-15 17:04:00
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/basicInfo.vue
-->
<template>
  <div class="basicInfo">
    <div class="RequirementConfirmation-content-item">
      <h3 class="header-title">基本信息</h3>
      <a-form-model class="basicInfo-form">
        <a-row :gutter="35" class="basicInfo-form-item">
          <a-col :span="6">
            <a-form-model-item label="需求标题">
              需要一批飞机盒
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="解决方式">
              无设计图，平台推荐服务商
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="需求所属类型">
              服装-飞机盒
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="手机号码">
              18380479260
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否需要设计">
              需要设计
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="部门">
              采购
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="邮箱">
              jiajiahm@163.com
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="需求预算">
              200元（单件）
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="数量">
              200件
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="发货方式">
              专车
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="需要设计的工艺类型">
              包装瓶
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="期望收货日期">
              2023-02-25
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="设计方案是否公开">
              公开
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="报价截止日期">
              2023-02-18
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否需要发票">
              是
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="内容物">
              需求
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="期望收货地区">
              上海市虹口区
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="需求数量">
              100件
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="需求描述">
              服装-飞机盒.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。服装-飞机盒.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。服装-飞机盒.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。服装-飞机盒.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。服装-飞机盒.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。服装-飞机盒.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="附件">
              <a-icon type="file" style="font-size: 40px; color: #0099ff;cursor: pointer;margin-top: 6px;" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div class="RequirementConfirmation-content-item">
      <h3 class="header-title">详细要求</h3>
      <a-form-model class="basicInfo-form" v-for="i in 4" :key="i" v-if="!showMore ? i < 3 : true">
        <h3 class="subTitle">名称{{ i }}:</h3>
        <a-row :gutter="80" class="basicInfo-form-item">
          <a-col :span="6">
            <a-form-model-item label="需求名称">
              箱子盖
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="材质">
              无
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="印刷需求">
              样品无需印刷
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="产品尺寸">
              238*22*134
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="产品包装状态">
              平躺
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="产品重量">
              20kg
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="测试认证">
              无
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="物流监控">
              防震标签
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="运输方式">
              空运
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="包装数量">
              单件包装
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="使用次数">
              一次性包装
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="流通线路">
              国内
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="装卸工具">
              叉车
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="特殊保护">
              空运
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="其他需求">
              无
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-row :gutter="80" class="basicInfo-form-item">
        <a-col :span="24" style="text-align: center">
          <a-button type="link" icon="down" v-if="!showMore" @click="showMore = true">展开</a-button>
          <a-button type="link" icon="up" v-else @click="showMore = false">收起</a-button>
        </a-col>
      </a-row>
    </div>
    <div class="RequirementConfirmation-content-item">
      <h3 class="header-title">订单动态</h3>
      <a-timeline>
        <a-timeline-item color="gray" v-for="i in 4" :key="i">
          <span class="time">2022-12-08 16:03:03</span>
          <div class="content">恭喜你匹配成功！可继续在关联订单446880718451179520继续与其交易</div>
        </a-timeline-item>
        <a-timeline-item color="gray">
          <a-button type="link">查看更多</a-button>
        </a-timeline-item>
      </a-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: 'basicInfo',
  data() {
    return {
      showMore: false
    }
  }
}
</script>

<style lang="less" scoped>
.RequirementConfirmation {
  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 15px;
    &::after {
      display: inline-block;
      margin-left: 30px;
      content: '生成需求单，请确认。';
      color: #626161;
      font-size: 14px;
      font-weight: normal;
    }
    &:not(:first-child)::after {
      display: none;
    }
  }
  &-content {
    padding: 0 40px;
    &-item {
      margin-top: 35px;
      width: 100%;
      border-radius: 8px;
      border: 1px dashed #bfbaba;
      padding: 40px 40px 20px;
      background: #fff;
      position: relative;
      &:first-of-type {
        margin-top: 20px;
      }

      ::v-deep .ant-timeline {
        padding-left: 140px;
      }
      ::v-deep .ant-timeline-item-content {
        position: relative;
        .time {
          position: absolute;
          left: -170px;
        }
        .content {
          padding: 8px 15px;
          height: 100px;
          background: #fff;
          color: #787878;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        }
      }
      .header-title {
        position: absolute;
        font-size: 15px;
        color: #605f5f;
        left: 20px;
        top: -10px;
      }
      .edit-btn {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  ::v-deep .ant-form-item-label {
    line-height: 25px;
    label {
      color: #626161 !important;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 25px;
    color: #333;
  }
  .basicInfo-form {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
    &-item {
      padding: 0 20px;
    }
  }
}
.subTitle {
  font-size: 15px;
  color: #d9001b;
}
</style>
