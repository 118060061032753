<!--
 * @Description: 发货单信息
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:00:59
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 22:19:38
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/shippingInfo.vue
-->
<template>
  <DrawerView :visible="visible" :width="960" :title="'查看收货单'" show-slot-title @confirm="handleOk"
              @cancel="handleCancel">
    <a-form-model
      class="info-form"
      :label-col="{
        span: 6,
      }"
      :wrapper-col="{
        span: 18,
      }"
      :colon="false"
    >

      <h2 class="page-title">
        <span class="title">收货信息</span>
      </h2>
      <div class="receiveInfo">
        <div style="display: flex ;padding: 10px 24px">
          <div style="width: 300px;padding-right: 40px;">收货人：{{ form.receiveName }}</div>
          <div style="width: 300px;">收货人电话：{{ form.receivePhone }}</div>
        </div>
        <div style="padding: 10px 24px">收货地址：
          {{ (form.address) }}
        </div>
      </div>
      <div class="receive-order">
        <div class="receive-descbox">
          <div class="receive-th">
            <div class="desc-title">「收货单一」</div>
            <div class="desc-time"> 收货时间&nbsp;
              <span v-if="form.realReceiveDate==null">{{ form.expectedDeliveryDate }}</span>
              <span v-else>{{  dayjs(form.realReceiveDate).format('YYYY-MM-DD') ||'-' }}</span></div>
            <div class="desc-operate" >
              <span   @click="handleDownLoad(form)"  style="cursor: pointer;" v-if="form.receiveStatus == 2">下载收货单</span>
            </div>
          </div>
          <div class="receive-tr">
            <div class="receive-td">发货方式：{{ form.shipmentTypeDictName }}</div>
            <div class="receive-td"> 物流编号：{{ form.shipmentNo || '-' }}</div>
            <div class="receive-td" style=" border-right:none;">
              <div style="display: flex;align-items: center">
                <span>发货状态：</span>
                <div v-if=" !form.shipmentStatus || form.shipmentStatus == 1" style="display: flex;align-items: center">
                  <img src="@/assets/warning-tip.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #EE4261">待发货</span>
                </div>
                <div v-if=" form.shipmentStatus ==2 && form.receiveStatus == 1" style="display: flex;align-items: center">
                  <img src="@/assets/goods-warning.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #FF6026">待收货</span>
                </div>
                <div v-if="form.shipmentStatus==2 && form.receiveStatus == 2" style="display: flex;align-items: center">
                  <img src="@/assets/success-goods.png" style="width: 16px;height: 16px;margin-right: 4px" />
                  <span style="color: #05C29C">已收货</span>
                </div>

              </div>
            </div>
          </div>
        </div>
        <a-table class="table-box" :columns="columns" :pagination="false" :data-source="form.shipmentItems" bordered>
          <template slot="receiveQuantity" slot-scope="text, record, index">
            {{ record.receiveQuantity||'-' }}
          </template>
        </a-table>
      </div>
      <!--      <a-row>-->
      <!--        <a-col :span="12">-->
      <!--          <a-form-model-item label="期望收货时间">-->
      <!--            <j-date placeholder="请选择" v-model="form.expectedDeliveryDate" style="width: 100%" disabled />-->
      <!--          </a-form-model-item>-->
      <!--        </a-col>-->
      <!--      </a-row>-->
      <!--      <a-row>-->
      <!--        <a-col :span="12">-->
      <!--          <a-form-model-item label="收货人">-->
      <!--            <a-input placeholder="请输入" v-model="form.receiveName" style="width: 100%" disabled></a-input>-->
      <!--          </a-form-model-item>-->
      <!--        </a-col>-->
      <!--        <a-col :span="12">-->
      <!--          <a-form-model-item label="手机号">-->
      <!--            <a-input placeholder="请输入" v-model="form.receivePhone" style="width: 100%" disabled></a-input>-->
      <!--          </a-form-model-item>-->
      <!--        </a-col>-->
      <!--        <a-col :span="24">-->
      <!--          <a-form-model-item-->
      <!--            label="收货地址"-->
      <!--            :label-col="{-->
      <!--              span: 3,-->
      <!--            }"-->
      <!--            :wrapper-col="{-->
      <!--              span: 21,-->
      <!--            }"-->
      <!--          >-->
      <!--            <a-input-->
      <!--              placeholder="请输入"-->
      <!--              class="lastBtn"-->
      <!--              v-model="form.receiveDetailAddress"-->
      <!--              style="width: 100%"-->
      <!--              :maxLength="20"-->
      <!--              disabled-->
      <!--            >-->
      <!--              <div class="NumberInfo" slot="suffix">-->
      <!--                {{ (form.receiveDetailAddress && form.receiveDetailAddress.length) || 0 }}/20-->
      <!--              </div>-->
      <!--            </a-input>-->
      <!--          </a-form-model-item>-->
      <!--        </a-col>-->
      <!--      </a-row>-->
    </a-form-model>

    <!--    <h2 class="page-title">-->
    <!--      <span class="title">收货单据</span>-->
    <!--    </h2>-->
    <!--    &lt;!&ndash; 预览单据图片 &ndash;&gt;-->
    <!--    <div class="order-pic" v-if="form.receiveFile">-->
    <!--      <img preview="1" :src="form.receiveFile" alt="" class="cover" v-if="form.receiveFile.IsPicture()" />-->
    <!--      <img src="/fileView.png" alt="" class="cover file" v-else @click="handleViewFile" />-->
    <!--      &lt;!&ndash; fileView &ndash;&gt;-->
    <!--      <a @click="handleDownLoad">下载</a>-->
    <!--    </div>-->
    <!--    <a-empty v-else description="暂无收货单据"></a-empty>-->
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
    </template>
  </DrawerView>
</template>

<script>
import { getAction, postAction, downGetFiles } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import JDate from '@/components/easyComponents/JDate.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import dayjs from 'dayjs'
// String 构造函数的原型对象的一个方法。
String.prototype.IsPicture = function() {
  //判断是否是图片 - strFilter必须是小写列举
  var strFilter = '.jpeg|.jpg|.png|.pic|'
  if (this.indexOf('.') > -1) {
    var p = this.lastIndexOf('.')
    //alert(p);
    //alert(this.length);
    var strPostfix = this.substring(p, this.length) + '|'
    strPostfix = strPostfix.toLowerCase()
    //alert(strPostfix);
    if (strFilter.indexOf(strPostfix) > -1) {
      //alert("True");
      return true
    }
  }
  //alert('False');
  return false
}

function downloadImage(url) {
  let name = url.split('/images/')[1]

  if (url.includes('/file/pdf/')) {
    name = url.split('/file/pdf/')[1]
  }
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch(() => alert('Failed to download image.'))
}

export default {
  name: 'transportsForm',
  components: {
    UploadDrag,
    JDate,
    DrawerView,
    JDictSelect
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        detailAddress: ''
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: [],
      uploadFiles: [],
      lastDate: null,
      orderNo: null,
      columns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width:300
        },
        {
          title: '需求名称',
          dataIndex: 'skuName',
          width:300

        },
        {
          title: '收货数量',
          dataIndex: 'receiveQuantity',
          width:300,
          scopedSlots: { customRender: 'receiveQuantity' }

        }
      ],
      dataSource: []
    }
  },
  methods: {
    dayjs,
    handleEdit(item) {
      console.log(item)
      this.loadRegionList()
      this.visible = true
      this.form = { ...item }
      console.log(this.form)
      console.log(this.form)
      this.dataSource = item.shipmentItems || []
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleViewFile() {
      window.open(this.form.receiveFile)
    },
    handleDownLoad(item) {
      // downloadImage(this.form.receiveFile)
      downGetFiles('/order/receive/download', {
        id: item.id
      }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', window.TemplateName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
        window.URL.revokeObjectURL(url) // 释放掉blob对象
      })
    },
    changeInfo(e) {
      this.form.deliveryAddress = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleOk() {
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          postAction('/order/transport/updateTransport', {
            ...this.form
          })
            .then((res) => {
              const { success, message } = res
              this.confirmLoading = false
              if (success) {
                this.handleCancel()
                this.$emit('ok')
              } else {
                this.$message.warning(message)
              }
            })
            .catch((e) => {
              this.confirmLoading = false
            })
        }
      })
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.regionList = data
        }
      })
    },
    /* 获取用户信息更新 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then((res) => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        }
      })
    },
    handleChangeRootId(flag = false) {
      this.deliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.form.deliveryCity = undefined
        this.form.deliveryDistrict = undefined
      }
      this.handleLoadCity('deliveryCity', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'deliveryCity') {
        this.deliveryCityList = []
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryCity = undefined)
        parentId = this.form.deliveryAddress
      }
      if (key == 'deliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryDistrict = undefined)
        parentId = this.form.deliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this[`${ key }List`] = data
        }
      })
    },
    handleCancel() {
      this.form = {
        detailAddress: ''
      }
      this.lastDate = null
      this.visible = false
      this.confirmLoading = false
      this.uploadFiles = []
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.page-title {
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 28px;
  }

  &:first-of-type {
    margin-top: -8px;
  }

  &.not-first {
    margin-top: 0;
  }

  font-size: 15px;
  color: #605f5f;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6026;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #ff6026;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    img.icon {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.table-box {
  min-height: auto !important;
border-left:0px !important;
  border-right: 0px !important;
  ::v-deep .ant-table-tbody tr:not(:last-of-type) > td {
    border-right: 1px solid #e8e9eb !important;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    border-right: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
    padding: 8px 10px;
    font-size: 14px;
  }

  ::v-deep .ant-table-column-title {
    font-size: 14px;
  }

  ::v-deep .ant-table-tbody tr > td {
    border-bottom: 1px solid #e8e9eb !important;
    padding: 5px 10px;
  }

  ::v-deep .ant-table-wrapper {
    min-height: auto !important;
    border-bottom: none;
  }

  ::v-deep .ant-table-row {
    height: 38px !important;
  }
}

.order-pic {
  width: 300px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;

  img.cover {
    width: 240px;
    height: 164px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;

    &.file {
      width: auto;
      border: 1px solid #e8e9eb;
      cursor: pointer;
    }
  }
}

.formInfo {
  ::v-deep .ant-select-selection__rendered {
    line-height: 38px !important;
  }
}


::v-deep .ant-tabs .ant-tabs-left-content {
  border-left: 0px !important;
}

::v-deep .step_form .ant-tabs-content {
  padding-left: 0px;
}

::v-deep .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0rpx;
  display: none;
}

::v-deep .ant-tabs .ant-tabs-left-bar {
  border-right: 0px
}

::v-deep .ant-tabs-bar {
  border-bottom: 0px;
}

.receiveInfo {
  display: flex;
  flex-direction: column;
  background: #F7F8FA;
  border-radius: 4px;
  padding: 16px 0px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* or 22px */
  color: #595959;
}

.receive-order {
  margin-top: 24px;
}

::v-deep .receive-order .ant-descriptions-view {
  border-radius: 4px 4px 0px 0px !important;

}

::v-deep .receive-order .ant-table-wrapper {
  border-bottom: none !important;
}

::v-deep .receive-order .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
}

::v-deep .receive-order .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px !important;
}

::v-deep .receive-order .ant-table-body table {
  border-top: none !important;
}

.receive-order {
  border-top-left-radius: 0px !important;
}

::v-deep .receive-order table {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none !important;
}

.send-delivery {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #131212;

}

.desc-title {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #131212;
  width: 311px;
  padding: 8px 16px;


}

.desc-time {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #131212;
  width: 311px;
  padding: 8px 16px;


}

.desc-operate {
  width: 311px;
  padding: 8px 16px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* 品牌主题色 */
  color: #FF6026;
  justify-content: flex-end;

}

.receive-th {
  display: flex;
  background: #FAFAFA;
  border: 1px solid #E8E9EB;
  justify-content: space-between;
}

.receive-tr {
  display: flex;
  border: 1px solid #E8E9EB;
  border-top: none;
  border-bottom: 0px;

}

.receive-td {
  padding: 10px 16px;
  width: 311px;
  border-right: 1px solid #E8E9EB;

}

::v-deep .receive-order .table-box .ant-table-thead > tr > th {
  padding: 10px 16px;
}

::v-deep .receive-order .table-box .ant-table-tbody tr > td {
  padding: 10px 16px;
}
</style>
